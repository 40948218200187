article.text-content {
  padding: 20px 0 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: fit-content;
  width: 100%;

  .inner {
    padding: 20px 15px 10px;
    position: relative;
    &::before {
      background-color: @page-bg;
      opacity: 0.7;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .text-wrapper {
      position: relative;
    }

    h1 {
      margin: 0 0 20px;
    }

    p, li {
      color: @text-alt;
      margin-bottom: 10px;
    }

    h1, h2, h3, h4, h5 {
      color: @heading-alt;
    }

    br {
      line-height: 10px;
    }
  }

  .tablet({
    .inner {
      width: calc(100% - 100px);
      margin: 0 auto;
    }
  })
}