.block.footer {
  padding: 80px 0;
  background-color: @primary-bg;

  .col {
    padding: 0 15px;
  }

  p, a, li {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 18px;
    padding: 0;
    margin-bottom: 15px;
  }

  .contact-details, .footer-nav, .social-links, .accessibility {
    margin-bottom: 20px;
  }

  .social-links {
    img {
      height: 32px;
      width: 32px;
    }
  }

  .contact-details {
    a {
      color: @text-alt;
    }
    &:hover, &:focus {
      opacity: 0.8;
    }
  }

  .footer-nav {
    a {
      color: @text-alt;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }

  .accessibility {
    display: flex;
    flex: row;
    .icon {
      img {
        width: 20px;
        height: auto;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }

  .legal {
    color: @button-bg-hover;
  }

  .bottom {
    a {
      color: @text-link;
      // Text is a bit hard to see otherwise
      font-weight: 600;
      &:hover, &:focus {
        color: @text-link-hover;
        text-decoration: underline;
      }
    }
  }

  .tablet({
    .top, .bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .contact-details, .social-links {
      flex: 1;
    }

    .footer-nav {
      flex: 2;
    }

    .footer-nav ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        width: 50%;
      }
    }

    .legal-acess {
      display: flex;
      flex-direction: row;
      .legal {
        margin-left: 10px;
      }
    }
  })

}