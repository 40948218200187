.block.communities {
  padding: 20px 0 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: fit-content;
  width: 100%;

  .inner {
    display: flex;
    justify-content: center;
  }

  .content {
    padding: 20px 15px 40px;
    position: relative;
    width: 100%;
    &::before {
      background-color: @page-bg;
      opacity: 0.7;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .title, .list, .map {
    position: relative;
  }

  .title {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    h1 {
      line-height: 1;
    }
  }

  .heading button {
    padding: 10px 30px 10px 15px;
    display:inline;
    margin: 0;
    position: relative;
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: @text;
    text-align: left;
    font-weight: 700;
    font-size: 1.6rem;
    border: 0;
    cursor: pointer;
    &::after {
      content: '';
      border-top: 6px solid;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      transition: transform 0.3s;
    }
    &:hover, &:focus {
      color: @text-link;
      text-decoration: underline;
    }
  }

  ul {
    list-style: none;
  }

  li {
    display: flex;
    flex-direction: column;
    margin-top: 5px;

    .collapsible {
      height: 0;
      overflow: hidden;
      position: relative;
      box-shadow: inset 0px 1px 1px #dddddd;
      background-color: #FFFFFF;
      border-radius: 0 0 5px 5px;
      transition: all 0.35s ease;
      &.transitioning {
        display: block;
      }
      &.showing {
        display: block;
        height: auto;
      }
      &.collapsed {
        display: none;
      }
    }

    .wrapper {
      padding: 15px;
    }

    .links {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .button, button {
        margin-top: 40px;
        position: relative;
        padding: 5px 10px;
        text-shadow: 0 1px 0 #890e21;
        border-radius: 2px;
        box-shadow: 0 1px 0 #5b0a16;
        border: none;
      }

      .website, .view-map {
        .button, button {
          padding-right: 4ch;
          height: 4rem;
          a {
            display: flex;
            align-items: center;
          }
        }
        .button::after, button::after {
          font-family: "FontAwesome";
          font-size: 1.4rem;
          position: absolute;
          right: 1.25ch;
          color: white;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .website {
        a::after {
          content: '\f08e';
        }
      }
      .view-map {
        button::after {
          content: '\f041';
        }
      }
    }

    .list {
      margin-bottom: 20px;
    }

    .info {
      display: flex;
      flex-direction: column;
      .image {
        margin-right: 15px;
      }
      img {
        height: 125px;
        width: auto;
        max-width: 100%;
        object-fit: contain;
        object-position: top;
      }
      a:hover, a:focus {
        text-decoration: underline;
      }
      .details {
        width: calc(100% - 165px);
      }
      * {
        font-weight: 700;
      }
    }

    &[data-collapsed="false"] {
      .heading button {
        border-radius: 5px 5px 0 0;
        &::after {
          transform: translateY(-50%) rotate(0deg);
        }
      }
    }
  }







  .tablet({
    .content {
      width: 80%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .list {
      padding-right: 15px;
      width: 50%;
      li {
        .info {
          flex-direction: row;
        }
        img {
          width: 150px;
          height: auto;
          max-height: 125px;
          object-fit: contain;
          object-position: top;
        }
      }
    }



    #communities-map {
      width: 50%;
      padding-left: 15px;
      height: 600px;
      background-color: #dddddd;
      background-image: url("/assets/img/map-assets/ajax-loader.gif");
      background-repeat: no-repeat;
      background-position: center;
    }
  });

  .ultra({
    .content {
      width: 100%;
    }
  });
}