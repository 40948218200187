.block.topbar{
  background: #000000;
  padding: 10px 0;
  border-bottom: 10px solid @button-bg;
  position: relative;

  .skip-link a {
    transition: all 0s;
  }
  .skip-link a:focus {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: @button-bg;
    color: @button-text;
    padding: 10px;
    z-index: 100;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .logo-wrapper, .mobile-toggle, nav {
    width: 100%;
    padding: 0 15px;
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    a {
      img {
        max-height: 150px;
      }
    }
  }

  .mobile nav{
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 0;
    overflow: hidden;
  }

  .mobile .mobile-open{
    height: fit-content;
  }

  .desktop nav ul {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
  }

  nav {
    * {
      font-size: 1.4rem;
      color: @text-alt;
      font-weight: 400;
    }
    li {
      display: flex;
      align-items: center;
      position: relative;
    }
    a, button {
      padding: 10px;
    }
    button {
      background-color: transparent;
      border: none;
      margin: 0;
      cursor: pointer;
      // Caret
      span::after {
        content: '';
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
  }

  nav .dropdown {
    display: none;
  }

  li {
    &:has(a:hover), &:focus-within {
      background-color: @secondary-bg;
    }
  }

  li.expanded {
    background-color: @secondary-bg;
    button {
      span::after {
        transform: rotate(180deg);
      }
    }
    .dropdown {
      display: block;
      position: absolute;
      background-color: @secondary-bg;
      top: 100%;
      left: 0;
      width: 100%;
      min-width: 160px;
      z-index: 999;
      ul {
        display: flex;
        flex-direction: column;
      }
      a {
        width: 100%;
        text-align: left;
      }
    }
  }

  .mobile li {
    .dropdown {
      left: 12rem;
      top: 0;
      width: calc(100% - 12rem);
    }
    button {
      span::after {
        transform: rotate(-90deg);
      }
    }
    &.expanded {
      button {
        span::after {
          transform: rotate(90deg);
        }
      }
    }
  }

  .dropdown a:focus, .dropdown a:hover {
    background-color: @button-bg-hover;
  }

  .mobile-toggle {
    button {
      float: right;
      margin: unset;
      padding: 0;
      background-color: transparent;
      border: none;
      padding: 9px 10px;
      span {
        display: block;
        width: 22px;
        height: 2px;
        background-color: @button-text;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }


  .tablet({
    padding: 10px 0 0;
    background: linear-gradient(90deg, #1a1a1b 0, #3f4041 50%, #1a1a1b);
    nav {
      ul {
        li:hover, li.expanded {
          .dropdown {
            padding-top: 10px;
            box-shadow: 0 8px 8px rgba(0, 0, 0, .175);
          }
        }
      }
    }
  });

  .vp(1200px, {
    padding: 0;
    .inner {
      display: flex;
      flex-direction: row;
    }

    .logo-wrapper {
      width: 30%;
      margin: 10px 0;
      justify-content: flex-start;
    }

    .main-nav {
      width: 70%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .desktop {
      nav {
        height: 100%;
        ul {
          height: 100%;
          justify-content: flex-end;
          li {
            height: 100%;
            align-items: flex-end;
            a:not(.dropdown-link), button {
              padding: 10px 10px 15px;
            }
          }
        }
      }
    }
  });
}