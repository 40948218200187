html {
	font-size: 62.5%;
	min-width: 300px;
	*, *::before, *::after {
		box-sizing: border-box;
	}
}

body{
	font-size: 1.4rem;
	line-height: 1.4;
	color: @text;
	font-family: @main;
  background-color: @primary-bg;
	&:has(.builder), &:has(.rs-editor) {
		background-color: transparent;
	}
}

img{
	display: block;
}

#mainwrap{
	overflow: hidden;
}

.inner{
	position: relative;
	margin: auto;
	max-width: 1170px;
	width: 100%;
}