.block.sitemap {
  padding: 20px 0 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: fit-content;
  width: 100%;

  .inner {
    display: flex;
    justify-content: center;
  }

  .inner {
    display: flex;
    justify-content: center;
  }

  .content {
    padding: 20px 15px 0;
    position: relative;
    width: 100%;
    &::before {
      background-color: @page-bg;
      opacity: 0.7;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .title, .map-list {
    position: relative;
  }

  .title {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  nav {
    margin-bottom: 20px;
    * {
      font-size: 1.4rem;
    }
    h1, h2, h3, h4, h5 {
      color: @text-alt;
      padding: 2px 0;
    }
    a {
      padding: 2px 0;
      display: block;
      width: fit-content;
      &:hover {
        text-decoration: underline;
      }
    }

  }

  .tablet({
    .content {
      width: 80%;
    }
  });

  .ultra({
    .content {
      width: 100%;
    }
  });

}