.block.mission-statement {
  background-color: @secondary-bg;
  .divider {
    content: "";
    height: 0;
    width: 0;
    border-top: 65px solid @primary-bg;
    border-right: 100vw solid transparent;
  }

  .divider.bottom {
    transform: rotate(180deg);
  }


  .content {
    padding: 20px 15px;
  }
  .title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  blockquote {
    position: relative;
    margin: 0;
    padding: 10px 20px;
    p {
      font-size: 1.8rem;
      text-align: center;
    }

    p::before {
      content: open-quote;
      font-family: Cabin, sans-serif;
      font-size: 7.2rem;
      line-height: 0.1rem;
      vertical-align: -2.5rem;
    }
    p::after {
      content: close-quote;
      font-family: Cabin, sans-serif;
      font-size: 7.2rem;
      line-height: 0.1rem;
      vertical-align: -3.5rem;
    }
  }

  &.builder {
    .divider {
      border-right: clamp(1px, 90vw, 1400px) solid transparent;
    }
  }
}