.block.development {
  padding: 20px 0 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: fit-content;
  width: 100%;

  .inner {
    display: flex;
    justify-content: center;
  }

  .content {
    padding: 20px 15px;
    position: relative;
    width: 100%;
    &::before {
      background-color: @page-bg;
      opacity: 0.7;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .title, .statement, .details {
    position: relative;
  }

  .title {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    h1 {
      line-height: 1;
    }
  }

  .statement, .sub-title {
    margin-bottom: 10px;
  }

  .details {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0;
    p {
      margin-bottom: 10px;
    }
    .detail-image {
      width: 100%;
      img {
        max-width: 100%;
        max-height: 125px;
      }
    }
    .detail-text {
      width: 100%;
      a:hover, a:focus {
        text-decoration: underline;
      }
    }
  }


  .tablet({
    .content {
      width: 80%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .details {
      flex-direction: row;
      gap: 30px;
      .detail-image {
        flex: 1;
      }
      .detail-text {
        flex: 3;
      }
    }
  });

  .ultra({
    .content {
      width: 100%;
    }
  });
}