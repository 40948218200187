.block.real-estate {
  padding: 20px 0 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: fit-content;
  width: 100%;

  label {
    color: @text-alt;
  }

  .inner {
    display: flex;
    justify-content: center;
  }

  .content {
    padding: 20px 15px 0;
    position: relative;
    width: 100%;
    &::before {
      background-color: @page-bg;
      opacity: 0.7;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .title, .list, .contact-us {
    width: 100%;
    text-align: left;
    position: relative;
  }

  .title {
    margin-bottom: 20px;
    h1 {
      line-height: 1;
    }
  }
    .heading button {
      padding: 10px 30px 10px 15px;
      display:inline;
      margin: 0;
      position: relative;
      width: 100%;
      height: 4rem;
      border-radius: 5px;
      background-color: #f1f1f1;
      color: @text;
      text-align: left;
      font-weight: 700;
      font-size: 1.6rem;
      border: 0;
      cursor: pointer;
      &::after {
        content: '';
        border-top: 6px solid;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        transition: transform 0.3s;
      }
      &:hover, &:focus {
        color: @text-link;
        text-decoration: underline;
      }
    }

  .collapsible {
    height: 0;
    overflow: hidden;
    position: relative;
    box-shadow: inset 0px 1px 1px #dddddd;
    background-color: #FFFFFF;
    border-radius: 0 0 5px 5px;
    transition: all 0.35s ease;
    &.transitioning {
      display: block;
    }
    &.showing {
      display: block;
      height: auto;
    }
    &.collapsed {
      display: none;
    }
  }

  .wrapper {
    padding: 15px;
  }

  .list {
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
  }

  li {
    margin-top: 5px;
    &[data-collapsed="false"] {
      button {
        border-radius: 5px 5px 0 0;
        &::after {
          transform: translateY(-50%) rotate(0deg);
        }
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    .image {
      max-width: 300px;
      max-height: 300px;
      img {
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    a:hover, a:focus {
      text-decoration: underline;
    }
    .details {
      width: 100%;
      p {
        margin-bottom: 10px;
      }
    }
  }

  .contact-us {
    padding: 0 15px;
  }
  .required-statement {
    margin-bottom: 10px;
    color: @text-alt;
  }

  .tablet({
    .info {
      flex-direction: row;
      .details {
        width: 66.6667%;
        padding-left: 30px;
      }
    }
    .content {
      width: 80%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .list {
      padding-right: 15px;
      width: 66.6667%;

      .image {
        width: 33.3333%;
      }
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: top;
      }
    }
    .contact-us {
      width: 33.3333%;
    }
  });

  .ultra({
    .content {
      width: 100%;
    }
  });
}