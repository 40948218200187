.block.header {
  width: 100%;
  position: relative;
  min-height: 676px;
  background-color: #890e21;

  .content {
    padding-bottom: 10px;
  }

  .content .left {
    margin-top: 60px;
    padding: 100px 15px;
    position: relative;
    blockquote {
      position: relative;
      margin: unset;
      z-index: 1;
    }
    .reference * {
      font-size: 2.4rem;
      font-style: italic;
    }
    .promise {
      position: relative;
      z-index: 1;
      font-size: 1.8rem;
      font-style: italic;
    }
    .verse, .promise, .reference {
      margin-bottom: 10px;
    }
    &::before, &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      box-shadow: 0 2px 7px #000;
    }

    &::before {
      background: @page-bg;
      transform: skew(0, 10deg);
    }

    &::after {
      background: @secondary-bg;
    transform: skew(0, -10deg);
    }
  }

  .content .right {
    margin-top: 100px;

    .title, .text-content {
      padding: 20px;
    }
    .title {
      margin-bottom: 20px;
      background: @secondary-bg;
    }

    .text-content {
      background: @page-bg;
    }
  }


  .background-overlay {
    position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    .background-image {
      position: absolute;
      visibility: hidden;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
      overflow: hidden;
      &.active {
        opacity: 1;
        visibility: visible;
      }
      img {
        width: 100%;
        height: 100%;
        min-height: 816px;
        object-fit: cover;
      }
    }
  }

  .tablet({
    .content {
      top: 0;
      padding: 0 15px;
      display: flex;
      flex-direction: row;
    }
    .content .left {
      flex: 1;
    }
    .content .right {
      flex: 3;
      margin-left: 15px;
      .title, .text-content {
        display: inline-block;
      }
      .text-content {
        margin-bottom: 10px;
      }
    }
  });

  &.builder {
      .inner {
        background-color: #890e21;
      }
      .background-overlay {
        position: relative;
        width: 500px;
        height: auto;
        margin: auto;
        .background-image {
          opacity: 1 !important;
          position: relative;
          visibility: visible !important;
          margin: 5px 0;
          img {
            min-height: unset;
          }
        }
      }
    }
}


