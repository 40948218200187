.block.history {
  padding: 20px 0 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: fit-content;
  width: 100%;

  .content {
    padding: 20px 15px 20px;
    position: relative;
    &::before {
      background-color: @page-bg;
      opacity: 0.7;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .details {
    position: relative;
  }

  .title {
    margin: 0 0 20px;
  }

  .text-content {
    p {
      margin-bottom: 10px;
    }
  }

  .tablet({
    .content {
      margin: 0 50px;
    }
  })
}