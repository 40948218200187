.block.team {
  padding: 20px 0 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: fit-content;
  width: 100%;

  .inner {
    display: flex;
    justify-content: center;
  }

  .content {
    padding: 20px 15px;
    position: relative;
    width: 100%;
    &::before {
      background-color: @page-bg;
      opacity: 0.7;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .title {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    position: relative;
  }

  .team-list {
    position: relative;
  }

  .collapsible {
    height: 0;
    background-color: #FFFFFF;
    box-shadow: inset 0px 1px 1px #dddddd;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    transition: all 350ms ease;
    p {
      margin-bottom: 10px;
    }
    &.transitioning {
      display: block;
      position: relative;
    }
    &.showing {
      display: block;
      height: auto;
    }
    &.collapsed {
      display: none;
    }
  }

  .bio {
    padding: 15px;
  }

  ul {
    list-style: none;
  }

  li {
    margin-top: 5px;
    overflow-y: hidden;
    &[data-collapsed="false"] {
      button {
        border-radius: 5px 5px 0 0;
        &::after {
          transform: translateY(-50%) rotate(0deg);
        }
      }
    }
  }

  .accordion-item button {
    padding: 10px 30px 10px 15px;
    display:inline;
    margin: 0;
    position: relative;
    width: 100%;
    min-height: 4rem;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: @text;
    text-align: left;
    font-weight: 700;
    font-size: 1.6rem;
    border: 0;
    cursor: pointer;
    transition: border-radius 0.3s ease;
    &::after {
      content: '';
      border-top: 6px solid;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      transition: transform 0.3s;
    }
    &:hover, &:focus {
      color: @text-link;
      text-decoration: underline;
    }
  }


  .tablet({
    .content {
      width: 80%;
    }
  });

  .ultra({
    .content {
      width: 100%;
    }
  });

  &.builder {
    li {
      margin-top: 5px;
        button {
          border-radius: 5px 5px 0 0;
          &::after {
            transform: translateY(-50%) rotate(0deg);
          }
        }
        .collapsible {
          height: auto;
        }
    }
  }
}