.block.contact {
  padding: 20px 0 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: fit-content;
  width: 100%;

  label {
    color: @text-alt;
  }

  .inner {
    display: flex;
    justify-content: center;
  }

  .content {
    padding: 20px 15px 0;
    position: relative;
    width: 100%;
    &::before {
      background-color: @page-bg;
      opacity: 0.7;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .title, .wrapper {
    position: relative;
  }

  .title, .subtitle {
    width: 100%;
    text-align: left;
  }

  .title {
    margin-bottom: 40px;
  }

  .subtitle {
    margin-bottom: 20px;
  }

  .contact-details {
    background-color: @secondary-bg;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;

    a {
      color: @text-alt;
    }
    .name {
      h4 {
        font-size: 1.8rem;
      }
    }
    .phone-numbers {
      margin-top: 15px;
      a {
        text-decoration: underline;
        &:hover, &:focus {
          opacity: 0.8;
        }
      }
    }
    .contact-email {
      a:hover, a:focus {
        text-decoration: underline;
      }
    }
    .contact-person, .contact-email {
      margin-bottom: 10px;
    }
  }

  .required-statement {
    margin-bottom: 10px;
    color: @text-alt;
  }




  .tablet({
    .content {
      width: 80%;
      .wrapper {
        display: flex;
        flex-direction: row;
        gap: 30px;
        .left {
          width: calc(41.6667% - 15px);
        }
        .right {
          width: calc(58.3333% - 15px);
        }
      }

    }
  });

  .ultra({
    .content {
      width: 100%;
    }
  });
}