.block.buttons {
  background-color: @secondary-bg;
  .divider {
    content: "";
    height: 0;
    width: 0;
    border-top: 65px solid @primary-bg;
    border-right: 100vw solid transparent;
  }

  .divider.bottom {
    transform: rotate(180deg);
  }

  .inner {
    padding: 0 15px;
  }

  .card {
    padding: 0 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        border-radius: 50%;
        max-height: 181px;
        aspect-ratio: 1/1;
        max-width: 181px;
        object-fit: cover;
        box-shadow: 0 2px 7px #000000;
      }
    }
    .title {
      margin: 20px 0;
      h2, h3, h4, h5 {
        line-height: 1.1;
        text-transform: capitalize;
      }
    }
    .text-content {
      margin-bottom: 10px;
    }

    .button {
      padding: 0;
      border: none;
      margin-bottom: 10px;
      a {
        display: block;
        background-color: #ffffff;
        background-image:  linear-gradient(180deg, #ffffff, #e6e6e6);
        border-radius: 3px;
        padding: 7px 14px;
        text-shadow: 0 1px 0 @text;
        color: @text;
        text-transform: capitalize;
      }

    }
  }

  .tablet({
    .cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .card {
        width: 25%;
      }
    }
  });

  &.builder {
    .divider {
      border-right: clamp(1px, 90vw, 1400px) solid transparent;
    }
  }
}




